<template>

  <Header />

  <main>
    <div class="container">

      <div class="upper-section">
        <h2>Dispatch Admin</h2>
      </div>

      <Tabs>
        <template #tabs>
          <router-link
            class="tab"
            to="/admin/account-requests"
          >
            Account Requests
          </router-link>
          <router-link
            class="tab"
            to="/admin/accounts"
          >
            Accounts
          </router-link>
          <router-link
            class="tab"
            to="/admin/contracts"
          >
            Contracts
          </router-link>
          <router-link
            class="tab"
            to="/admin/patch-templates"
          >
            Patch Templates
          </router-link>
          <router-link
            class="tab"
            to="/admin/mailer"
          >
            Mailer
          </router-link>
        </template>
      </Tabs>

      <router-view />

    </div>
  </main>

  <Footer />

</template>

<script>

  import Tabs from '@/components/page/Tabs.vue'
  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'

  export default {
    components: {
      Tabs,
      Header,
      Footer,
    },
  }

</script>

<style lang="stylus" scoped>

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply pb-16
    @apply mx-auto

  .upper-section
    @apply mt-12
    @apply mb-6

    h2
      @apply mb-0

</style>
